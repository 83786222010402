import Header from "../components/header";
import Footer from "../components/footer";
import { useEffect, useState } from "react";
import Faqs from "../components/faqs";
import Section from "../components/sections";
import Aos from 'aos';

const Course = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    const [course, setCourse] = useState({
        name: <div className="card-img-top bg-dark-subtle" style={{"height":"50px"}}></div>,
        description : '<div class="card-body bg-body-secondary" style="height:720px"}></div>',
        content_creator: {
            name: '', intro: ''
        }, sections: [],
        price: '',
        ribbon: ''
    });
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (loaded) return;
        let uid = window.location.pathname.split('/').pop();
        fetch('https://api.kingcoda.net/course/'+uid+"/x", {
            headers: {
            'Content-Type':'application/json', 'Connection-Paradigm': 'json'
        }}).then(res => res.json())
        .then(jr => {
            setCourse(jr);
            setLoaded(true);
        });
        Aos.init();
    }, [loaded]);
    return <>
    <Header />
    <main className='container p-3 py-4'>
    <div className='row'>
<div className="col-12 col-lg-3 col-md-3 order-lg-1 order-3 neon-green pb-3">
    <div className="mx-auto bg-white p-3 rounded-3 shadow">
    <p>Your trainer for this course</p>
    <div className="trainer-picture my-3">
        <img src={course.content_creator.avatar} className="w-100 shadow" alt={course.content_creator.name} />
    </div>
    <h3>{course.content_creator.name}</h3>
    <p>{course.content_creator.intro}</p>
    </div>
</div>
        <div className="col-12 col-lg-6 col-md-5 mb-3 order-2 order-lg-2 aqua">
        <div className="ribbon-corner-mobile mb-4 course">{course.ribbon}</div>
        <h1 className="fs-1 fw-bolder">{course.name}</h1>
        <div className="fs-5 my-3" dangerouslySetInnerHTML={{__html: course.description.replace('\n', '<br />')}}></div>
            <h2>Course Features</h2>
            <ul className="fs-5">
                <li>Taught in Hindi for a <i>very easy understanding</i>.</li>
                <li>1:1 Doubt Clearing. When you have a question, just ask on Discord.</li>
                <li>Join community of developers on Discord</li>
                <li>Live Project Creation</li>
                <li>Start from Very Basic to Advanced</li>
                <li>Highly Qualified Trainer</li>
                <li>Learn at your own pace</li>
                <li>100% Job Assurance</li>
            </ul>
            {
                (course.ribbon.startsWith('Full Stack')) ?
                <>
                <h2>Complimentary Courses</h2>
            <ul className="fs-5">
                <li>Bootcamp Course for HTML and CSS</li>
                <li>Bootcamp Course for Linux (Ubuntu)</li>
                <li>Bootcamp Course for Git.</li>
            </ul></> :
            <></>
            }
        </div>
        <div className="col-12 col-lg-3 col-md-4 order-1 order-lg-3">
            <div id="pfd" className="p-2 shadow">
            <p className="fw-bolder fs-1">Price ₹{course.price}</p>
        <button className="btn btn-lg btn-buy w-100">Buy Now</button>
        <div className="text-center my-3">
        <a href="/gift-a-course">Gift this course to someone</a>
        </div>
        </div>
        </div>
    </div>
    <div className="row mt-3">
        <div className="col-lg-9 col-md-8 col-12 sunrise">
        <h2>Course Curriculum</h2>
        <Section section={course.sections} />
    <Faqs />
    </div>
    <div className="col-12 d-block d-sm-block d-md-none d-xl-none d-lg-none mt-5">
        <br />
        <hr />
        <h2>{course.name}</h2>
<p className="fw-bolder fs-1">Price ₹{course.price}</p>
    <button className="btn btn-lg btn-buy w-100">Buy Now</button>
    <div className="text-center my-3">
    <a href="/gift-a-course">Gift this course to someone</a>
    </div>
    </div>
    </div>
    </main>
    <Footer />
    </>
}

export default Course;