import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Castle from './screens/castle';
import Course from './screens/course.js';
import Courses from './screens/courselist';
import Content from './screens/content';
import Gate from './screens/gate';
import Otp from './screens/otp';
import Header from './components/headerpvt';
import Dashboard from './screens/dashboard';
import MyCourses from './screens/mycourses.js';

const root = ReactDOM.createRoot(document.getElementById('groot'));
if (window.localStorage.getItem('secret') === 'yellow') {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Castle />} />
        <Route path="/course/:name/:uuid" element={<Course />} />
        <Route path="/our-courses/" element={<Courses />} />
        <Route path="/access" element={<Gate />} />
        <Route path="/access/otp" element={<Otp />} />
        <Route path="/my-courses" element={<MyCourses />} />
        <Route path="course/:name/:xid/content/:uuid" element={<Content />} />
        <Route path="/i" element={<Header />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="course/:name/:xid/content/:uuid" element={<Content />} />
        </Route>
      </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}

