import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Courses = () => {
const [isLoading, setLoading] = useState(true);
const [courses, setCourses] = useState(<></>);
const sanitize = (string) => {
    string = string.replace('\n', '<br />');
    let re = new RegExp(/<[^>]+\/>/)
    return (re.test(string)) ? string : '[REDACTED]'
}

useEffect(() => {
    fetch('https://api.kingcoda.net/courses',
    {
        headers: {'Connection-Paradigm':'json'}
    }).then(
    (res) => res.json().then(
        (jr) => {
            let cx = jr.courses.map(jx => 
                <div key={jx.uuid} className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'>
        <div className="card shadow-lg rounded">
        <div className="ribbon-corner-mobile">{jx.ribbon}</div>
            <img src={jx.banner_path} className="card-img-top" alt="" />
            <div className="card-body">
                <h2 className="card-title">{jx.name}</h2>
                <p className="card-text" dangerouslySetInnerHTML={{__html: sanitize(jx.description)}}></p>
                <Link to={"/course/"+jx.name.replace(/([^\w]+|\s+)/g, '-') + jx.ribbon.replace(/[\s-]+/g, '-') + '/'+jx.uuid} className="stretched-link btn-lg btn btn-primary stcrs">Click for more details</Link>
            </div>
        </div>
        </div>);
            setCourses(cx);
            setLoading(false);
        })
    ).catch((error) => console.log('Something went wrong'));
}, []);

return <>
<div className='row mt-1 g-5'><div className='col-12'><h2>Choose your Stack and Let's roll!</h2></div></div>
<div className="row g-5">
    {isLoading ?
<>
<div className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'><div className="card shadow-lg rounded w-100"><div className="card-img-top bg-dark-subtle" style={{"height":"143px"}}></div><div className="card-body bg-body-secondary" style={{height: '270px'}}><div className="card-title bg-body-secondary"></div><div className="card-text bg-body-secondary"></div><div className="btn btn-lg btn-primary stcrs bg-dark w-100" style={{height: "50px"}}></div></div></div></div>
<div className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'><div className="card shadow-lg rounded w-100"><div className="card-img-top bg-dark-subtle" style={{"height":"143px"}}></div><div className="card-body bg-body-secondary" style={{height: '270px'}}><div className="card-title bg-body-secondary"></div><div className="card-text bg-body-secondary"></div><div className="btn btn-lg btn-primary stcrs bg-dark w-100" style={{height: "50px"}}></div></div></div></div>
<div className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'><div className="card shadow-lg rounded w-100"><div className="card-img-top bg-dark-subtle" style={{"height":"143px"}}></div><div className="card-body bg-body-secondary" style={{height: '270px'}}><div className="card-title bg-body-secondary"></div><div className="card-text bg-body-secondary"></div><div className="btn btn-lg btn-primary stcrs bg-dark w-100" style={{height: "50px"}}></div></div></div></div>
<div className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'><div className="card shadow-lg rounded w-100"><div className="card-img-top bg-dark-subtle" style={{"height":"143px"}}></div><div className="card-body bg-body-secondary" style={{height: '270px'}}><div className="card-title bg-body-secondary"></div><div className="card-text bg-body-secondary"></div><div className="btn btn-lg btn-primary stcrs bg-dark w-100" style={{height: "50px"}}></div></div></div></div>
<div className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'><div className="card shadow-lg rounded w-100"><div className="card-img-top bg-dark-subtle" style={{"height":"143px"}}></div><div className="card-body bg-body-secondary" style={{height: '270px'}}><div className="card-title bg-body-secondary"></div><div className="card-text bg-body-secondary"></div><div className="btn btn-lg btn-primary stcrs bg-dark w-100" style={{height: "50px"}}></div></div></div></div>
<div className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'><div className="card shadow-lg rounded w-100"><div className="card-img-top bg-dark-subtle" style={{"height":"143px"}}></div><div className="card-body bg-body-secondary" style={{height: '270px'}}><div className="card-title bg-body-secondary"></div><div className="card-text bg-body-secondary"></div><div className="btn btn-lg btn-primary stcrs bg-dark w-100" style={{height: "50px"}}></div></div></div></div>
</>
:
courses
}
</div>
</>
}

export default Courses;