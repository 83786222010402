import { useEffect, useState } from "react"

const Counter = ({activateResend}) => {
    const [seconds, setSeconds] = useState(90);
    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => {
                let s = seconds - 1;
                setSeconds(s);
            }, 1000);
            return;
        }
        activateResend();
    }, [seconds, activateResend]);
return <p><span>Resend OTP</span> (wait {seconds} seconds)</p>
}

export default Counter;