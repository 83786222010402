const Header = () => <>
    <nav className="navbar navbar-expand-md bg-body-tertiary border-bottom py-0">
  <div className="container-xxl">
    <a className="navbar-brand" href="/">
      <img src="/images/logo.svg" alt="kingcoda logo" />

      <span className="ms-2">KINGCODA</span>
      </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link" href="/my-courses">My Courses</a>
        </li>
        <li className="nav-item">
          <div className="avatarsection px-4 py-3">
            <img src="/images/avatar.png" alt="avatar" id="avatar" className="me-2" />Keval Domadia
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
</>

export default Header;