import { useEffect } from "react";

import { useState } from "react";

const Resend = ({deactivateResend, updateError}) => {
    var jrr = '';
    const [rsb, setRsb] = useState(<div></div>);
    useEffect(() => {
        /* eslint-disable */
        turnstile.ready(function () {
            turnstile.render("#cfts", {
                sitekey: '0x4AAAAAAAMI_K4rAGXGxefG',
                callback: function(token) {
                    jrr = token;
                    setRsb(<div><span role="button" className="text-primary" onClick={resendotp}>Resend OTP</span></div>)
                },
            });
        });
        /* eslint-enable */
    }, []);
    const resendotp = () => {
        let x = window.localStorage.getItem('tolkien');
        let resendcounter = parseInt(window.localStorage.getItem('resendcounter'));
        let phn = x.split('[]')[1];
        setRsb(<div>Sending...</div>);
        fetch("https://api.kingcoda.net/checkuser", {
            headers: {'Connection-Paradigm':'json', 'Content-Type':'application/json'},
            method: 'POST', credentials: 'include', body: JSON.stringify({phone: phn, cftoken: jrr}) })
            .then(res => res.json())
            .then(rj => {
            if (rj.message === undefined) {
                setTimeout(() => {
                    deactivateResend();
                }, 2000);
            } else {
                updateError(rj.message);
            }
            setRsb(<div>Sent!</div>);
            if (resendcounter > 3) {
                window.localStorage.setItem('lot', (new Date()).toISOString());
            }
            window.localStorage.setItem('resendcounter', resendcounter + 1);
        });
    }
    return <div>{rsb}<div id="cfts"></div></div>
}

export default Resend;