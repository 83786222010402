import Courses from "../components/courses";
import Faqs from "../components/faqs";
import Footer from "../components/footer";
import Header from "../components/header";

const Courselist = () => {
    return <>
    <Header />
    <main className='container-xxl p-3 py-4'>
    <Courses />
    <Faqs />
    </main>
    <Footer />
    </>
}

export default Courselist;