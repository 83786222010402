const Footer = () => <>
<footer className="bg-dark text-white py-3 container-fluid">
    <div className="container-xxl">
    <div className="row">
    <div className="col-12 col-md-5">
        KINGCODA and its mark "crown" are registered trademark.
    </div>
    <div className="col-12 col-md-7">
    <div>Your data is managed in accordance to the <a href="/terms#privacy" className="text-white" target="_blank" rel="noopener nofollow">Privacy Policy</a> and <a href="/terms#usage" className="text-white" target="_blank" rel="noopener nofollow">Terms of Use</a> policy</div>
    <div>Copyright &copy; 2023 - 2024 Kingcoda Education. All Rights Reserved.</div>
    <div className="small">Logos mentioned on course banners and elsewhere belong to their respective owners / foundations.</div>
    </div>
    </div>
    </div>
</footer>
</>

export default Footer;