import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const Sidebar = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [course, setCourse]= useState({});
    const [content, setContent] = useState({});
    const [loaded, setLoaded] = useState(false);
    let courseuid = window.location.pathname.split('/')[3];
    let coursename = window.location.pathname.split('/')[2];
    useEffect(() => {
        if (window.innerWidth < 768) {
            setIsMobile(true);
        }
        fetch('https://api.kingcoda.net/course/'+courseuid+"/x", 
        {
            headers: { 'Content-Type':'application/json', 'Connection-Paradigm': 'json' }
        }).then(res => res.json()).then(jr => {
            let content = jr.sections.map(jx => {
                let id = jx.name.replace(/[\s.]/g, '-')
                return <li key={id} className="mb-1">
                    <button className="btn btn-toggle rounded collapsed" data-bs-toggle="collapse" data-bs-target={'#'+id} aria-expanded="false" style={{width: "100%", textAlign: "left"}}>
                    {jx.name}
                    </button>
                    <div className="collapse show" id={id}>
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ps-4 pt-1 ms-auto " style={{backgroundColor: "#D3D3D3"}}>
                    {jx.contents.map(jy => {
                        return <li><Link to={"/course/"+coursename+"/"+courseuid+"/"+"content/"+jy.uuid} className="link-dark rounded ml-5">{jy.name}</Link> <p>{jy.isCompleted ? '✓': ''}</p> </li>
                    })}    
                    </ul>
                    </div>
                </li>
            }
            )
            setContent(content);
            setCourse(jr);
            setLoaded(true);
        }
        ).catch((error) => console.log('Something went wrong'));
}, [loaded]);
    
    
    return <>
    <div className="flex-shrink-0  bg-white" style={{width:"280px"}}>
    <a href="/" className="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
      <svg className="bi me-2" width="30" height="24"></svg>
      <span className="fs-5 fw-semibold"> {course.name} </span>
    </a>
    <ul className="list-unstyled ps-0">
        {loaded ? content : <></>}
    </ul>
  </div>
    </>
}

export default Sidebar;