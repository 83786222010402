import Header from "../components/header";
import Footer from "../components/footer";
import Counter from "../components/counter";
import Resend from "../components/resend";
import { useCallback, useEffect, useRef, useState } from "react";

const Otp = (e) => {
    useEffect(() => {
        let pn = window.localStorage.getItem('tolkien');
        if (pn == null) {
            window.location.href="/access";
            return;
        }
        let [xn, pnt] = pn.split('[]');
        setXtn(xn);
        setPhn(pnt);
    }, []);
    const [xtn, setXtn] = useState('');
    const [error, setError] = useState('');
    const [phn, setPhn] = useState('');
    const [ResendBtn, setResendBtn] = useState(false);
    const loginbtn = useRef();
    const err = useCallback((message) => { setError(message) }, []);
    const x = useCallback(() => {
        let resendcounter = window.localStorage.getItem('resendcounter');
        let r = window.localStorage.getItem('lot');
        let less60 = ((new Date()) - (new Date(r)) > 60 * 60 * 1000);
    if (parseInt(resendcounter) > 3 && less60) {
        setError('Too many OTP requests. Please try again in 60 minutes.');
    } else { setResendBtn(true); } }, []);
    const y = useCallback(() => { setResendBtn(false); }, []);
    const login = () => {
        setError('');
      let r = Array.from(document.getElementsByClassName('otp'));
      let otp = Array.from(r).map(x => x.value).join('');
      if (otp.length !== 6) {
        setError('OTP is invalid');
        return;
      }
        fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        fetch('https://api.kingcoda.net/verify', { method: 'POST', keepalive: true, credentials: 'include',
            headers: { 'Connection-Paradigm':'json', 'Content-Type':'application/json',
            'Y-Access-Token': xtn
            },
            body: JSON.stringify({ otp: otp, userip: data.ip })
        }).then(res => res.json())
        .then(rj => {if (rj.success === 'logged in') { window.location.href = '/i/dashboard'; } else {
            setError(rj.message); return;
        }});
      });
        return;
    }
    const moveNext = (event) => {
        if (event.target.value === '') {
            return;
        }
        if (parseInt(event.target.value).toString() === 'NaN') {
            event.target.value = '';
            return;
        }
        event.target.nextElementSibling.focus();
    }
    const moveEnd = (event) => {
        if (event.target.value === '') {
            return;
        }
        if (parseInt(event.target.value).toString() === 'NaN') {
            event.target.value = '';
            return;
        }
    }
    return <>
    <Header />
    <main className="container-xxl">
        <div className="row gx-0">
            <div className="col-12 col-md-6 align-self-center d-flex justify-content-center">
                <div className="card p-3 my-3 w-75">
                    <div className="form-group my-2">
                    <label htmlFor="otp" className="form-label">Enter your OTP</label>
                    <div className="input-group">
                        <input id="otp" type="text" onChange={moveNext} className="form-control otp d-inline-block" inputMode="numeric" maxLength={1} />
                        <input type="text" onChange={moveNext} className="form-control otp d-inline-block" inputMode="numeric" maxLength={1} />
                        <input type="text" onChange={moveNext} className="form-control otp d-inline-block" inputMode="numeric" maxLength={1} />
                        <input type="text" onChange={moveNext} className="form-control otp d-inline-block" inputMode="numeric" maxLength={1} />
                        <input type="text" onChange={moveNext} className="form-control otp d-inline-block" inputMode="numeric" maxLength={1} />
                        <input type="text" onChange={moveEnd} className="form-control otp d-inline-block" inputMode="numeric" maxLength={1} />
                    </div>
                    </div>
                    <span className="text-danger text-end">{error}</span>
                    <div className="form-group mt-3">
                    <button className="btn btn-primary w-100" ref={loginbtn} onClick={login}>Login</button>
                    </div>
                    <div className="mt-3">
                        <p><small>The OTP was sent to the following number: {phn}.</small></p>
                        { (ResendBtn) ? <Resend deactivateResend={y} updateError={err} /> : <Counter activateResend={x} />}
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6">
                <img src="/images/access2.jpg" alt="login access" className="w-100" />
            </div>
        </div>
    </main>
    <Footer activateResend={x} />
    </>
}

export default Otp;