import Header from "../components/header";
import Footer from "../components/footer";
import { useEffect, useState } from "react";
import {Link} from "react-router-dom";
const MyCourses = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    const [isLoading, setLoading] = useState(true);
    const [courses, setCourses] = useState(<></>);
    const sanitize = (string) => {
        string = string.replace('\n', '<br />');
        let re = new RegExp(/<[^>]+\/>/)
        return (re.test(string)) ? string : '[REDACTED]'
    }
    useEffect(() => {
        fetch('https://api.kingcoda.net/my-courses',{
            headers: {'Connection-Paradigm':'json', 
            "allowCredentials": "true", 
            "Content-Type": "application/json"
            }, 
            credentials: 'include'
        }).then((res)=> res.json().then((jr) => {
            let cx = jr.courses.map(jx =>
                
                    <div key={jx.uuid} className="col-12 col-md-6 col-lg-4">
                        <div className="card mb-3">
                            <img src={jx.banner_path} className="card-img-top" alt="" />
                            <div className="card-body">
                                <h5 className="card-title">{jx.name}</h5>
                                <p className="card-text" dangerouslySetInnerHTML={{__html: sanitize(jx.description)}}></p>
                                <button className="btn btn-primary">View Course</button>
                                </div>
                        </div>
                    </div>)
                
            setCourses(cx);
            setLoading(false);
        })
        ).catch((error) => console.log('Something went wrong'));
    }, []);

    return <>
    <Header />
    <main className="container-xl gx-0" style={{flex: 1}}>
        <div className="row">
            <div className="col-12">
                <h1 className="text-center">My Courses</h1>
            </div>
            <div  className="col-12">
                <div className="row">
            {isLoading ?
<>
<div className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'><div className="card shadow-lg rounded w-100"><div className="card-img-top bg-dark-subtle" style={{"height":"143px"}}></div><div className="card-body bg-body-secondary" style={{height: '270px'}}><div className="card-title bg-body-secondary"></div><div className="card-text bg-body-secondary"></div><div className="btn btn-lg btn-primary stcrs bg-dark w-100" style={{height: "50px"}}></div></div></div></div>
<div className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'><div className="card shadow-lg rounded w-100"><div className="card-img-top bg-dark-subtle" style={{"height":"143px"}}></div><div className="card-body bg-body-secondary" style={{height: '270px'}}><div className="card-title bg-body-secondary"></div><div className="card-text bg-body-secondary"></div><div className="btn btn-lg btn-primary stcrs bg-dark w-100" style={{height: "50px"}}></div></div></div></div>
<div className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'><div className="card shadow-lg rounded w-100"><div className="card-img-top bg-dark-subtle" style={{"height":"143px"}}></div><div className="card-body bg-body-secondary" style={{height: '270px'}}><div className="card-title bg-body-secondary"></div><div className="card-text bg-body-secondary"></div><div className="btn btn-lg btn-primary stcrs bg-dark w-100" style={{height: "50px"}}></div></div></div></div>
<div className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'><div className="card shadow-lg rounded w-100"><div className="card-img-top bg-dark-subtle" style={{"height":"143px"}}></div><div className="card-body bg-body-secondary" style={{height: '270px'}}><div className="card-title bg-body-secondary"></div><div className="card-text bg-body-secondary"></div><div className="btn btn-lg btn-primary stcrs bg-dark w-100" style={{height: "50px"}}></div></div></div></div>
<div className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'><div className="card shadow-lg rounded w-100"><div className="card-img-top bg-dark-subtle" style={{"height":"143px"}}></div><div className="card-body bg-body-secondary" style={{height: '270px'}}><div className="card-title bg-body-secondary"></div><div className="card-text bg-body-secondary"></div><div className="btn btn-lg btn-primary stcrs bg-dark w-100" style={{height: "50px"}}></div></div></div></div>
<div className='col-12 col-md-6 col-xl-4 d-flex align-items-stretch'><div className="card shadow-lg rounded w-100"><div className="card-img-top bg-dark-subtle" style={{"height":"143px"}}></div><div className="card-body bg-body-secondary" style={{height: '270px'}}><div className="card-title bg-body-secondary"></div><div className="card-text bg-body-secondary"></div><div className="btn btn-lg btn-primary stcrs bg-dark w-100" style={{height: "50px"}}></div></div></div></div>
</>
:
courses
}
</div>
</div>
        </div>
    </main>
    <Footer />
    </>
}
export default MyCourses;