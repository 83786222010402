import Header from "../components/header";
import Footer from "../components/footer";
import { useEffect, useRef, useState } from "react";

const Gate = (e) => {
    const phonenumber = useRef();
    const sendbtn = useRef();
    const cfts = useRef();
    const [snedotp, setSnedotp] = useState(<button className="btn btn-primary mt-1" disabled>Send OTP</button>)
    const [error, setError] = useState('');
    let jrr = '';

    const sendotp = () => {
        if (jrr.length < 5) {
            setError('Security verification failed. Refreshing page in 5 seconds.');
            setTimeout(() => window.location.reload(), 5000);
            return;
        }
        if (phonenumber.current.value === '9876543210') {
            setError('Phone number is invalid. Please enter a valid mobile number to receive OTP');
            return;
        }
        let phn = phonenumber.current.value;
        let re = new RegExp(/^([6-9]\d{9})$/);
        if (!re.test(phn)) {
            setError('Phone number is invalid. Please enter a valid mobile number to receive OTP');
            return;
        }
        setError('');
        phonenumber.current.disabled = 'disabled';
        sendbtn.current.innerHTML = 'Sending...';
        sendbtn.current.disabled = 'disabled';
        fetch("https://api.kingcoda.net/checkuser", {
            headers: {'Connection-Paradigm':'json', 'Content-Type':'application/json'},
            method: 'POST', credentials: 'include', body: JSON.stringify({phone: phn, cftoken: jrr}) })
            .then(res => res.json())
            .then(rj => {
            document.getElementsByClassName('my-3')[0].classList.add('animate__backOutUp','animate__animated');
            window.localStorage.setItem('tolkien', rj.token+'[]'+phn);
            window.localStorage.setItem('resendcounter', 1);
            window.location.href = '/access/otp';
        });
    }
    useEffect(() => {
        fetch("https://api.kingcoda.net/ping").then(res => { if (res.status === 200) { window.location.href = "/i/dashboard" }});
        /* eslint-disable */
        turnstile.ready(function () {
            turnstile.render("#cfts", {
                sitekey: '0x4AAAAAAAMI_K4rAGXGxefG',
                callback: function(token) {
                    jrr = token;
                    setSnedotp(<button className="btn btn-primary mt-1" ref={sendbtn} onClick={sendotp}>Send OTP</button>);
                },
            });
        });
        /* eslint-enable */
    }, [])
    return <>
    <Header />
    <main className="container-xxl gx-0">
        <div className="row gx-0">
            <div className="col-12 col-md-6 align-self-center d-flex justify-content-center">
                <div className="p-3 my-3">
                    <div className="form-group my-2">
                    <label htmlFor="phonenumber" className="form-label">Enter your mobile number</label>
                        <div className="input-group px300">
                        <span className="input-group-text" id="india">🇮🇳 +91</span>
                            <input type="text" id="phonenumber" ref={phonenumber} className="form-control w-75" placeholder="Enter mobile number" />
                        </div>
                    </div>
                    <span className="text-danger text-end">{error}</span>
                    <div className="form-group mt-1">
                    <div id="cfts" ref={cfts}></div>
                    {snedotp}
                    </div>
                    <div className="mt-3">
                        <p><small>By providing phone number and clicking on Send OTP, I agree to all the <a href="/terms">Terms and Conditions</a>.</small></p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 bg-black d-none d-md-block">
                <img src="/images/access.jpg" alt="login access" className="w-100" />
            </div>
        </div>
    </main>
    <Footer />
    </>
}

export default Gate;