import Header from '../components/header';
import Footer from '../components/footer';
import Faqs from '../components/faqs';
import Courses from '../components/courses';
import { useEffect } from 'react';
import Aos from 'aos';

const Castle = () => {
    useEffect(() => { Aos.init() });
    return <>
    <Header />
    <main className='container-xxl p-3 py-4'>
        <div className='row'>
        <div className='col-12 col-md-4 d-flex align-items-center d-sm-flex d-md-none text-center my-3'>
                <h3 className='display-1 fw-bolder'>CRAFT THE MASTERY WITHIN YOU</h3>
            </div>
            <div className='col-12 col-md-4 d-flex align-items-center d-none d-md-flex'>
                <img src="/images/mastery.svg" alt="craft the mastery within you" className="d-flex" />
            </div>
            <div className='col-12 col-md-8'>
            <img src="/images/ai.jpg" className="w-100" alt="AI generated representation of human programming on a computer" />
            </div>
            <div className="col-12 my-4">
<div className='display-4 fw-bold text-center life' data-aos="fade-up" data-aos-easing="linear">Life is full of choices!</div>
<p className='display-4 fw-bold text-center life2 my-5' data-aos="fade-up" data-aos-easing="linear">Choose to <span className='underlined'>Become a Masterpiece</span>!</p>
</div>
<div className='col-12 text-center'>
<h2>Watch this video to Help you TAKE A DECISION!</h2>
</div>
<video controls></video>
            </div>
            {<Courses />}
        <Faqs />
    </main>
    <Footer />
    </>
}

export default Castle;