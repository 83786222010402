const Header = () => <>
    <nav className="navbar navbar-expand-md bg-body-tertiary border-bottom">
  <div className="container-xxl">
    <a className="navbar-brand" href="/">
      <img src="/images/logo.svg" alt="kingcoda logo" />

      <span className="ms-2">KINGCODA</span>
      </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item mx-1">
          <a className="nav-link" href="/our-courses">Our Courses</a>
        </li>
        <li className="nav-item mx-1">
          <a className="nav-link" href="/about">About</a>
        </li>
        <li className="nav-item mx-1">
          <a className="nav-link" href="/contact">Contact</a>
        </li>
        <li className="nav-item mx-1">
          <a className="nav-link active" href="/access">Get Started</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
</>

export default Header;