const Faqs = () => <>
<br />
<div className='row border-1 py-3 my-3 aqua'>
    <div className='col-12'>
    <p className='fs-2'><b>Courses Features:</b></p>
    <ul id='features'>
        <li> Lectures are taught in <i>Modern</i> Hindi - Jo <b>dimag me utre</b>!</li>
        <li> Chat <b>1:1 for doubt clearance</b> via Discord. <b>Hindi me pucho</b> ya fir English mein, sab chalega!</li>
        <li> Go from Zero to Hero. <b>No previous knowledge required</b>. Bus aa jao.</li>
        <li> Course designed for various talent level, from <b>Absolute Beginner to Working Professional</b>.</li>
        <li> Install the <b>Mobile Phone App to watch the course or use the website</b>. Sab suvidha hai!</li>
        <li> Access to <b>Private Facebook Community</b> of other students.</li>
        <li> Access to <b>Private Discord Community</b> of other students.</li>
        <li> <b>Certificate of Completion</b> on completing all lectures of your course.</li>
    </ul>
    </div>
</div>
<div className='row mt-5'>
        <div className='col-12 col-xl-8'>
        <div className="card border-dark mb-3">
  <div className="card-header">Frequently Asked Questions</div>
  <div className="card-body">
    <h5 className="card-title">What languages do you support?</h5>
    <p className="card-text">We support Hindi and English for doubt clearence. Lectures are taught in Hindi.</p>
  </div>
  <div className="card-body">
    <h5 className="card-title">How to approach you for doubt-solving?</h5>
    <p className="card-text">The best way to approach is via our Discord channel where you can just open the chat ask your questions.<br />Our team is mostly online and can provide faster resolution.</p>
  </div>
  <div className="card-body">
    <h5 className="card-title">Will I get a certificate?</h5>
    <p className="card-text">All courses come with Certificate of Completion.<br />However, to prove your metal and earn Certificate of Achievement, clear your exam where your code will be graded manually and feedback will be provided.<br />
    Once you clear the exam, your Certificate of Achievement will be mailed to your address.<br />
    Exam vouchers can be purchased after finishing the course for ₹590/-.</p>
  </div>
  <div className="card-body">
    <h5 className="card-title">I don't know how to code, where will you start from?</h5>
    <p className="card-text">Start with Basic. But, if you want to take this career seriously then sign up for Full-Stack courses, which comes with complimentary "Bootcamp" course which can get you started with. It will cover all basics of operating system, versioning system, basic HTML and CSS, which is like a foundating for your programming journey.</p>
  </div>
  <div className="card-body">
    <h5 className="card-title">For how long do we have access to the course?</h5>
    <p className="card-text">18 months. Everybody has different speed of learning.<br />Ideally it takes 3 months to 9 months to finish the course.<br />Take your time, study well.<br />If you need further extension, just email us before the course access expires.</p>
  </div>
  <div className="card-body">
    <h5 className="card-title">What happens when there is an update?</h5>
    <p className="card-text">If there is a breaking update, we will update the module and notify you.<br />Access to course will be extended.</p>
  </div>
</div>
</div>
        <div className="col-12 col-xl-4">
          <div>
          <h3 className="fs-1"><u>we play fair</u></h3>
          <p className="fs-3 my-3 fw-bolder">🙅🏻 No Upsell!</p>
          <p className="fs-3 my-3 fw-bolder">🙅🏻 No Gimmick!</p>
          <p className="fs-3 my-3 fw-bolder">🙅🏻 No Extra Charges!</p>
          
          <p className="fs-3 my-3 fw-bolder">👍🏼 Honest Display Price</p>
          <p className="fs-3 my-3 fw-bolder">👍🏼 Easy Content</p>
          <p className="fs-3 my-3 fw-bolder">👍🏼 Friendly Teaching Style</p>
          </div>
          <div className="mt-5">
            <h3>Download our app</h3>
            <div className="my-3">
            <a href="https://play.google.com/">
            <img src="/images/google-play.png" alt="google play" className="w-75" />
            </a>
            </div>
            <div className="mb-5">
            <a href="https://play.google.com/">
            <img src="/images/apple-appstore.png" alt="apple appstore" className="w-75" />
            </a>
            </div>
          </div>
        </div>

    </div>
</>
export default Faqs;