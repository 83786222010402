import Headerpvt from "../components/headerpvt";
import Sidebar from "../components/contentsidebar";
import Videoplayer from "../components/videoplayer";
import { useEffect, useState } from "react";
const Content = () => {
    
    
    return <>
    <Headerpvt />
    <main className="d-flex">
    <Sidebar  />
    <Videoplayer />
    </main>
    </>
}

export default Content;
