import {useState, useEffect} from 'react';

const Videoplayer = () => {
    const [loaded, setLoaded] = useState(false);
    const [video, setVideo] = useState({});
    let contentuid = window.location.pathname.split('/')[5];
    let courseuid = window.location.pathname.split('/')[3];
    useEffect(() => {
        if (loaded) return;
        fetch('https://api.kingcoda.net/course/'+courseuid+'/content/'+contentuid, {
            headers: {
            'Content-Type':'application/json', 'Connection-Paradigm': 'json'
        }, 
        credentials: 'include'
    }).then(res => res.json())
        .then(jr => {
            console.log(jr);
            setVideo(jr);
            setLoaded(true);
        });
    }, [loaded]);
    return <>
    <div style={{position: "relative", paddingTop: "50%"}}>
        <iframe src={video.url} loading="lazy" style={{position: "absolute", height: "100%", width: "100%"}} allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
    </div>
    <h2 className="fs-1 fw-bolder">{video.name}</h2>
    
    </>
}

export default Videoplayer;