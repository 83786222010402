import { Link } from "react-router-dom";

const Section = ({section}) => {
    var i = 0;
    if (section.length === 0) {
        return <><p>nothing here yet...</p></>
    }
    let r = section.map(one => { i++;
        return <div className="card" key={i}>
                <div className="card-title bg-warning" data-aos="flip-up">
                    <h4 className="p-2">{one.name}</h4>
                </div>
            <div className="card-body">
            <table className="table">
                <thead>
                    <tr>
                        <th className="col-9 col-md-10">Chapter title</th><th className="col-3 col-md-2">Duration</th>
                    </tr>
                </thead>
            <tbody id="course-list">
            { one.contents.map(inside => <tr key={inside.uuid} data-aos="flip-left">
                <td>{inside.name}{inside.freePreview ? <span> (<Link to={'/i' + window.location.pathname + '/content/' + inside.uuid}>free preview</Link>) </span> : ''}</td><td>{inside.duration}</td>
                </tr>)}
            </tbody>
            </table>
            </div>
            </div>
        }
    );
    return <>{r}</>
}

export default Section;